 
<template>
  <v-main style="background-color:#F2F3F8">
     <v-container>
 

        <v-row >
          <!-- <v-col cols="12" sm="1">
          </v-col> -->

          <!--메인:왼쪽 열  -->
          <v-col
            cols="12"
            lg="6">

            <v-sheet 
            elevation="1"
              rounded="lg"
              style="padding:25px"
              min-height="300">

              <h2>EOSQuest.io Lightpaper</h2><br>

              <v-btn outlined color="primary" @click="openLink">EOSQuest.io Lightpaper</v-btn>

              <br><br>

              <h2>More Features Comming!</h2><br>

               -More Daily Claimable Tokens<br>
               -Convenience features<br>
               -EOS QNA<br>
               -Ranking System<br>

              <br>
              <h3  style="color:blue">Updates Logs:</h3><br>

               <strong>[2022-01-12]</strong><br>
               -VIO TOKEN One Click Claim and Trade (Only 50 VIO)<br>
               -Proxy Voting<br>
               -Buy Ram with EOS or Bytes<br>
               -Mobile response on the main screen(메인화면 모바일 대응)<br><br>

               <strong>[2022-01-18]</strong><br>
               -Token Multiple Transfer: A function that reads the column value of Excel data and transfers tokens to multiple accounts at the same time (엑셀열 복사로 다중 계정에 EOS 전송)<br>
               -NFT Multiple Transfer: A function that reads the column value of Excel data and Transfers NFT to multiple accounts at the same time(엑셀열 복사로 다중 계정에 NFT 전송)(requested from manermakeman)<br>
               -Power Up(Pay)<br>
               -Anchor session restore (사이트 Refresh시에 로그인된 앵커세션 로드)<br>
               -Change the arrangement of the top icon.(상단 아이콘배치 변경)(reported from maplenut.gm)<br><br>

               <strong>[2022-01-22]</strong><br>
               -Token page mobile response.(토큰페이지 모바일 대응)<br>
               -STAKE/UNSTAKE<br>
               -CPU/NET STAKE status update.(CPU/NET 스테이크 상태 업데이트)<br><br>

                <strong>[2022-03-03]</strong><br>
               -Server Hosting migration (호스팅변경)<br>
               -Applied ssl(사이트 SSL 적용)<br>
               -The template page shows information at the top.(템플릿페이지는 상단에 정보가 표출)<br><br>

               <strong>[2022-03-10]</strong><br>
               -Pomelo: Adding Diamond Potion Claim function(포멜로 : 다이아몬드 포션 클레임 기능 추가)<br><br>

               <strong>[2022-04-21]</strong><br>
               -Add EOS Calendar (이오스 캘린더 추가)<br>
               -Change HOME NEWS UI (홈 뉴스항목 UI 수정)<br><br>
               
               <strong>[2022-07-06]</strong><br>
               -Pomelo: Adding Roket Potion Claim function(포멜로 : 로켓 포션 클레임 기능 추가)<br><br>

               <strong>[2022-08-24]</strong><br>
               -New QUEST UI(퀘스트페이지 UI 변경)<br>
               -Update DApps Pages and Add Category(DApp 페이지 UI변경 및 항목추가)<br><br>

               <strong>[2022-11-28]</strong><br>
               -Account Asset and Resourse API Change<br>
               -Remove Transaction History<br><br>

               <strong>[2022-12-02]</strong><br>
               -Pomelo: Adding Robot Potion Claim function(포멜로 : 로봇 포션 클레임 기능 추가)<br><br>

               
               <strong>[2022-12-05]</strong><br>
               -Add Account's Tokens List(계정의 토큰 리스트 출력)<br>
               -Account validation Check when transfer token(토큰 전송시 전송할 대상이 유효한지 검사)<br><br>

               <strong>[2023-05-10]</strong><br>
               -홈에 가격정보추가(상단,왼쪽,오른쪽)<br>
               -구글 로그인 기능 추가<br>
               -프로파일 페이지 추가(프로필정보,코멘트)<br>
               -무료 EOS 계정생성 추가(구글 로그인 사용자)<br>
               -무료 파워업 기능 추가(EOSQuest제공)<br>
             
            </v-sheet>
          </v-col>
 
          <!--메인:오른쪽 열  -->
          <!-- <v-col
            cols="12"
            sm="3">

            <v-sheet
              rounded="lg"
              min-height="300">

            
           
            </v-sheet>

          </v-col> -->

        </v-row>



      </v-container>
  </v-main>
</template>

<script>

export default ({
  
  methods: {
    

    openLink()
    {
      this.snackbar = false;
      var url = 'https://eosquest.notion.site/EOSQuest-io-Lightpaper-Ver-1-1-0-Korean-and-English-ea2a5f333ba843b49f0cf2d939c58c6c';
      window.open(url, '_blank');
    },

  },
  mounted() {
     this.$vuetify.goTo(0)
  },
})
</script>
